import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div>
      <div className="flexed">
        <div className="icon-links flexed">
          <div className="icon-cards">
            <a href="https://www.pornhub.com/model/maskedtube">
              <img
                src="images/icons/pornhub.png"
                className="icon-link-img"
                alt="pornhub-icon"
              />
              <h3>PornHub</h3>
            </a>
          </div>
          <div className="icon-cards">
            <a href="https://onlyfans.com/maskedtube">
              <img
                src="images/icons/onlyfans.png"
                className="icon-link-img"
                alt="onlyfans-icon"
              />
              <h3>OnlyFans</h3>
            </a>
          </div>
          <div className="icon-cards">
            <a href="https://maskedtube.manyvids.com/">
              <img
                src="images/icons/manyvids.png"
                className="icon-link-img"
                alt="manyvids-icon"
              />
              <h3>ManyVids</h3>
            </a>
          </div>
        </div>
        <div className="icon-links flexed">
          <div className="icon-cards">
            <a href="https://instagram.com/masked_tube">
              <img
                src="/images/icons/instagram.png"
                className="icon-link-img"
                alt="instagram-icon"
              />
              <h3>Instagram</h3>
            </a>
          </div>
          <div className="icon-cards">
            <a href="https://twitter.com/masked_tube">
              <img
                src="images/icons/twitter.png"
                className="icon-link-img"
                alt="twitter-icon"
              />
              <h3>Twitter</h3>
            </a>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
